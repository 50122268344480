<template>
  <v-row class="campaign">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between align-baseline">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/campaigns" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>{{ data.name }}
          </router-link>
        </div>
        <v-btn elevation="0" width="184" height="44" color="secondary">Resume</v-btn>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <div v-else>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
          <div class="d-flex flex-wrap justify-space-between mb-sm-0 mb-3">
            <div class="text-uppercase text-h5 font-weight-bold">General</div>
            <v-hover v-slot="{ hover }">
              <div class="f18 status" :class="getColorClass(data.status.name)">
                {{ data.status.title }}
                <v-fade-transition>
                  <div v-if="hover" class="accent pa-3 text-left rounded mt-3 status-info">
                    <div class="d-flex justify-space-between align-center">
                      <div class="f14">Moderation</div>
                      <div class="warning rounded-circle" style="width: 8px; height: 8px"></div>
                    </div>
                    <div class="f14 opasity--text">
                      The campaign is on hold due to moderation. It should take a short time. The campaign will be launched in case it does
                      not violate service terms. You can contact support in case of urgency.
                    </div>
                    <v-divider class="white"></v-divider>
                    <div class="d-flex justify-space-between align-center">
                      <div class="f14">Blocked</div>
                      <div class="error rounded-circle" style="width: 8px; height: 8px"></div>
                    </div>
                    <div class="f14 opasity--text">
                      The campaign is blocked due to the service terms violation. You can contact support to resolve the issue.
                    </div>
                    <v-divider class="white"></v-divider>
                    <div class="d-flex justify-space-between align-center">
                      <div class="f14">Submitted</div>
                      <div class="blue lighten-1 rounded-circle" style="width: 8px; height: 8px"></div>
                    </div>
                    <div class="f14 opasity--text">The campaign is scheduled and waiting for the launch.</div>
                    <v-divider class="white"></v-divider>
                    <div class="d-flex justify-space-between align-center">
                      <div class="f14">Started</div>
                      <div class="success rounded-circle" style="width: 8px; height: 8px"></div>
                    </div>
                    <div class="f14 opasity--text">The campaign is started and running.</div>
                    <v-divider class="white"></v-divider>
                    <div class="d-flex justify-space-between align-center">
                      <div class="f14">Low balance</div>
                      <div class="gray rounded-circle" style="width: 8px; height: 8px"></div>
                    </div>
                    <div class="f14 opasity--text">
                      The campaign is stopped due to the low balance. Please refill your account balance and resume the campaign.
                    </div>
                    <v-divider class="white"></v-divider>
                    <div class="d-flex justify-space-between align-center">
                      <div class="f14">Finished</div>
                      <div class="input rounded-circle" style="width: 8px; height: 8px"></div>
                    </div>
                    <div class="f14 opasity--text">The campaign is finished. You can view campaign results and statistics.</div>
                  </div>
                </v-fade-transition>
              </div>
            </v-hover>
          </div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="6">
                <div>Sender ID</div>
                <div class="opasity--text">{{ data.sender_id }}</div>
                <div class="mt-3">Selected recipients type</div>
                <div class="opasity--text">{{ data.targets_type.title }}</div>
                <div v-if="data.phones" class="mt-3">Target phone numbers</div>
                <div v-if="data.phones" class="input--text link" @click="modal = true">Open list</div>
                <div v-if="data.group" class="mt-3">Group</div>
                <div v-if="data.group" class="opasity--text">{{ data.group.name }}</div>
              </v-col>
              <v-col cols="12" md="6">
                <div>Sending mode</div>
                <div class="opasity--text">{{ data.send_option.title }}</div>
                <div class="mt-3">Compaign start date</div>
                <div class="opasity--text">{{ new Date(data.send_after).toLocaleString() }}</div>
                <div class="mt-3">Link Tracking</div>
                <div class="f14 white--text tracking" :class="data.track_link_opening ? 'success' : 'error'">
                  {{ data.track_link_opening ? 'On' : 'Off' }}
                </div>
              </v-col>
            </v-row>
            <div class="mt-5 text-h6">Message Preview:</div>
            <div class="f18 opasity--text">{{ data.message_text }}</div>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold">Routes and cost</div>
          <div class="px-sm-10 pt-sm-5">
            <v-card flat tile outlined>
              <v-data-table hide-default-footer outlined fixed-header :headers="headers" :items="data.routes"></v-data-table>
            </v-card>
            <div class="text-right text-uppercase mt-3">
              Total price: <span class="text-h5"> {{ data.total }}</span>
            </div>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 mt-5">
          <div class="d-flex flex-wrap justify-space-between px-sm-10 px-4 pt-sm-10 pt-4 mb-4">
            <div class="text-uppercase text-h5 font-weight-bold">Messages</div>
            <div class="msg-search">
              <v-text-field
                v-model="search"
                placeholder="Search by phone"
                prepend-inner-icon="mdi-magnify"
                hide-details
                clearable
                filled
                @click:clear="clearSearch"
                outlined
                dense
                required
                color="input"
                @change="(page = 1), getMsg()"
              >
              </v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <div>
            <v-data-table class="p-table" hide-default-footer fixed-header :headers="headersMsg" :items="msg.result">
              <template v-slot:header.status>
                Status
                <v-menu bottom :close-on-content-click="true" transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="status == 'all' ? 'opasity--text white' : 'white--text input'"
                      class="pa-0"
                      height="24"
                      min-width="24"
                      x-small
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-filter mdi-18px</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-hover v-slot="{ hover }" v-for="item in msgStatus" :key="item.name">
                      <div
                        @click="valueSelect(item.name)"
                        class="link px-3 py-2 m-1"
                        :class="[status == item.name ? 'gray lighten-1' : '', hover ? 'opasity lighten-4' : '']"
                      >
                        {{ item.title }}
                      </div>
                    </v-hover>
                  </v-card>
                </v-menu>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id" height="52" class="link" @click="openMsg(item.id)">
                    <td>{{ new Date(item.date).toLocaleString() }}</td>
                    <td>+{{ item.to }}</td>
                    <td>{{ item.cost }}</td>
                    <td class="text-right">{{ item.status }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <v-divider></v-divider>
            <v-row class="my-2 pb-2 px-10" align="center" justify="end">
              <span class="grey--text">Rows per page:</span>
              <div class="perPageSelect">
                <v-select
                  v-model="itemsPerPage"
                  :items="itemsPerPageArray"
                  menu-props="auto"
                  hide-details
                  single-line
                  dense
                  @change="getMsg"
                ></v-select>
              </div>
              <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 grey--text">{{ page }} of {{ msg.pages }}</span>
              <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </div>
        </v-card>
        <v-card v-if="data.track_link_opening" elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold">Statistic</div>
          <div class="px-sm-10 py-sm-5">
            <v-card tile outlined>
              <div class="d-flex flex-wrap justify-space-between align-center px-5 py-3">
                <div class="text-h6">Campaign statistic</div>
              </div>
              <v-divider></v-divider>
              <v-row class="px-5 py-3">
                <v-col cols="12" md="8" class="py-6">
                  <div class="d-flex flex-wrap justify-space-around">
                    <div class="graph">
                      <div class="big"></div>
                      <div class="medium"></div>
                      <div class="small"></div>
                      <div class="little"></div>
                    </div>
                    <div>
                      <div class="graph-item">
                        <div class="primary--text">{{ stat.messages_percent }}%</div>
                        <div class="opasity--text">{{ stat.messages_total }}/{{ stat.segments_total }}</div>
                      </div>
                      <div class="graph-item">
                        <div class="primary--text">{{ stat.sent_percent }}%</div>
                        <div class="opasity--text">{{ stat.messages_sent }}/{{ stat.segments_sent }}</div>
                      </div>
                      <div class="graph-item">
                        <div class="primary--text">{{ stat.delivered_percent }}%</div>
                        <div class="opasity--text">{{ stat.messages_delivered }}/{{ stat.segments_delivered }}</div>
                      </div>
                      <div class="graph-item">
                        <div class="primary--text">{{ stat.link_clicks_percent }}%</div>
                        <div class="opasity--text">{{ stat.link_clicks }}</div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="12" md="4">
                  <div class="d-flex">
                    <div>
                      <div class="blue rounded-circle mt-2" style="width: 10px; height: 10px"></div>
                    </div>
                    <div class="opasity--text ml-2">Total number of messages/segments</div>
                  </div>
                  <div class="d-flex">
                    <div>
                      <div class="blue lighten-2 rounded-circle mt-2" style="width: 10px; height: 10px"></div>
                    </div>
                    <div class="opasity--text ml-2">Number of messages/segments successfully sent</div>
                  </div>
                  <div class="d-flex">
                    <div>
                      <div class="blue lighten-3 rounded-circle mt-2" style="width: 10px; height: 10px"></div>
                    </div>
                    <div class="opasity--text ml-2">Number of messages/segments delivered</div>
                  </div>
                  <div class="d-flex">
                    <div>
                      <div class="blue lighten-4 rounded-circle mt-2" style="width: 10px; height: 10px"></div>
                    </div>
                    <div class="opasity--text ml-2">Number of link opening(If link tracking enabled)</div>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card>
            <v-card tile outlined class="mt-5">
              <div class="d-flex flex-wrap justify-space-between align-center px-5 py-3">
                <div class="text-h6">Campaign link statistics</div>
                <div>
                  <v-select
                    class="field42"
                    v-model="period"
                    :items="periods"
                    item-text="title"
                    item-value="name"
                    menu-props="auto"
                    hide-details
                    single-line
                    outlined
                    dense
                    @change="getDataLinkStat"
                    height="42"
                  ></v-select>
                </div>
              </div>
              <v-divider></v-divider>
              <v-tabs vertical hide-slider class="stat">
                <!-- <v-tabs-slider color="input"></v-tabs-slider> -->
                <v-tab
                  :id="item.tab"
                  class="text-capitalize tab-block font-weight-bold text-body-1 justify-start"
                  active-class="input white--text"
                  :disabled="!item.content"
                  v-for="item in items"
                  :key="item.tab"
                  @click="(type = item.value), getDataLinkStat()"
                >
                  {{ item.tab }}
                </v-tab>
                <v-tab-item v-for="item in items" :key="item.tab">
                  <v-card flat>
                    <v-data-table hide-default-footer :headers="item.content">
                      <template v-if="!listStat.length" v-slot:no-data>
                        <v-card tile flat color="transparent" class="d-flex justify-center align-center">
                          <div class="text-center">Sorry, but nothing found</div>
                        </v-card>
                      </template>
                      <template v-else v-slot:body>
                        <tbody>
                          <tr v-for="item in listStat.length" :key="item.name">
                            <td>{{ item.name }}</td>
                            <td class="text-right">{{ item.clicks_total }}</td>
                            <td>
                              <div class="d-flex align-center">
                                {{ item.clicks_percent }}%
                                <v-progress-linear class="ml-2" height="20" :value="item.clicks_percent"></v-progress-linear>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </div>
        </v-card>
      </div>
    </v-col>
    <v-dialog v-model="modal" max-width="536">
      <v-card class="pa-md-10 pt-md-5 px-sm-5 py-sm-10 py-5 px-4">
        <div class="text-uppercase text-h5 font-weight-bold">Target phone numbers</div>
        <v-card tile flat max-height="312" class="overflow-y-auto d-flex flex-wrap align-start align-self-start mt-7">
          <v-card class="py-1" flat tile width="50%" v-for="item in data.phones" :key="item">{{ item }}</v-card>
        </v-card>
        <v-divider class="mb-5 mt-7"></v-divider>
        <div class="d-flex flex-wrap justify-space-between">
          <v-btn @click="modal = false" text width="148" height="48">Close</v-btn>
          <v-btn elevation="0" width="148" height="48" class="bw input--text" @click="copy">
            <v-icon left>mdi-file-multiple</v-icon>
            Copy
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      modal: false,
      headers: [
        { value: 'country', text: 'Country', sortable: false },
        { value: 'mcc', text: 'MCC', sortable: false },
        { value: 'mnc', text: 'MNC', sortable: false },
        { value: 'messages_total', text: 'SMS Count', sortable: false },
        { value: 'price', text: 'Price', sortable: false },
        { value: 'total', text: 'Amount', align: 'end', sortable: false },
      ],
      search: this.$route.query.src || '',
      itemsPerPage: Number(this.$route.query.page_size) || 10,
      page: Number(this.$route.query.page) || 1,
      status: this.$route.query.status || 'all',
      period: 'last_7_days',
      headersMsg: [
        { value: 'date', text: 'Date', sortable: false },
        { value: 'to', text: 'Target', sortable: false },
        { value: 'cost', text: 'Cost', sortable: false },
        { value: 'status', text: 'Status', align: 'end', sortable: false },
      ],
      type: 'countries',
      periods: [
        { name: 'last_7_days', title: 'Last 7 days' },
        { name: 'last_30_days', title: 'Last 30 days' },
        { name: 'last_90_days', title: 'Last 90 days' },
      ],
      items: [
        // { tab: 'demographic', content: '' },
        {
          tab: 'country',
          value: 'countries',
          content: [
            { value: 'country', text: 'Country', sortable: false },
            { value: 'total', text: 'Users, total  ', align: 'end', sortable: false },
            { value: 'user', text: 'Users,%', sortable: false },
          ],
        },
        {
          tab: 'city',
          value: 'cities',
          content: [
            { value: 'city', text: 'City', sortable: false },
            { value: 'total', text: 'Users, total  ', align: 'end', sortable: false },
            { value: 'user', text: 'Users,%', sortable: false },
          ],
        },
        {
          tab: 'browser',
          value: 'browsers',
          content: [
            { value: 'browser', text: 'Browser', sortable: false },
            { value: 'total', text: 'Users, total  ', align: 'end', sortable: false },
            { value: 'user', text: 'Users,%', sortable: false },
          ],
        },
        {
          tab: 'operation system',
          value: 'operation_systems',
          content: [
            { value: 'os', text: 'OS', sortable: false },
            { value: 'total', text: 'Users, total  ', align: 'end', sortable: false },
            { value: 'user', text: 'Users,%', sortable: false },
          ],
        },
        // { tab: '', value: '', content: '' },
      ],
    };
  },
  mounted() {
    this.getData();
    this.getMsg();
    this.getDataLinkStat();
    this.$store.dispatch('getPagination');
    // this.$store.dispatch('getPeriods');
    this.$store.dispatch('getMsgStatus');
  },
  methods: {
    // demographicItem(item) {
    //   if (item == 'city' || item == 'country') {
    //     document.getElementById('demographic').classList.add('white--text', 'input');
    //   } else {
    //     document.getElementById('demographic').classList.remove('white--text', 'input');
    //   }
    //   this.getDataLinkStat(item);
    // },
    getColorClass(block) {
      if (block == 'moderation') {
        return 'warning';
      } else if (block == 'blocked') {
        return 'error';
      } else if (block == 'submitted') {
        return 'blue lighten-1';
      } else if (block == 'started') {
        return 'success';
      } else if (block == 'finished') {
        return 'input';
      } else if (block == 'balance_low') {
        return 'gray';
      } else if (block == 'scheduled') {
        return 'blue lighten-1';
      }
    },
    notifi(btn) {
      if (btn == 'delete') {
        this.$notify({ message: 'Group delete', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'copy') {
        this.$notify({ message: 'Phone numbers were copied to your clipboard', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'copyError') {
        this.$notify({ message: 'Sorry, error copied', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'search400') {
        this.$notify({ message: 'Error. not data', type: 'warning', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    copy() {
      const string = this.data.phones.join('\n');
      const container = document.querySelector('.v-dialog--active');
      this.$copyText(string, container)
        .then(() => {
          this.notifi('copy');
        })
        .catch(() => {
          this.notifi('copyError');
        });
    },
    valueSelect(status) {
      this.status = status;
      this.getMsg();
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getMsg();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getMsg();
    },
    nextPage() {
      if (this.page != this.msg.pages) {
        this.page += 1;
        this.getMsg();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getMsg();
      }
    },
    async getData() {
      await this.$store.dispatch('getCampaignItem').finally(() => {
        this.load = false;
      });
    },
    async getDataLinkStat() {
      await this.$store.dispatch('getCampaignLinkStat', `?period=${this.period}&type=${this.type}`).catch((e) => {
        this.getDataError(e);
      });
    },
    async getMsg() {
      await this.$store
        .dispatch('getCampaignMsg', {
          pageSize: this.itemsPerPage,
          search: this.search,
          page: this.page,
          status: this.status,
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'account__not_found') {
        this.$store.dispatch('setCampaignMsg', {});
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setCampaignMsg', {});
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
    openMsg(id) {
      this.$router.push({ name: 'Messages', params: { msgId: id } });
    },
  },
  watch: {
    data() {
      setTimeout(() => {
        if (this.data.track_link_opening) {
          this.$store.dispatch('getCampaignItemStat');
          // document.getElementById('demographic').classList.add('white--text', 'input');
        }
      }, 1000);
    },
    // periods(val) {
    //   if (val.length) {
    //     this.period = val[0].name;
    //     this.getDataLinkStat(this.items[0].value);
    //   }
    // },
  },
  computed: {
    data() {
      return this.$store.getters.campaignItem;
    },
    msg() {
      return this.$store.getters.campaignMsg;
    },
    stat() {
      return this.$store.getters.campaignItemStat;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
    // periods() {
    //   return this.$store.getters.periods;
    // },
    msgStatus() {
      return this.$store.getters.msgStatus;
    },
    listStat() {
      return this.$store.getters.campaignLinkStat;
    },
  },
  destroyed() {
    this.$store.dispatch('setCampaignItem', { status: {}, send_option: {}, group: {}, targets_type: {} });
    this.$store.dispatch('setCampaignItemStat', {});
    this.$store.dispatch('setCampaignMsg', {});
    this.$store.dispatch('setCampaignLinkStat');
  },
};
</script>

<style lang="scss">
.campaign {
  .status {
    text-align: center;
    line-height: 42px;
    padding: 0 28px;
    width: max-content;
    height: 42px;
    border-radius: 24px;
    color: white;
  }
  .status-info {
    position: absolute;
    line-height: 24px;
    left: 50%;
    z-index: 9999;
    width: 364px;
  }
  .tracking {
    width: 58px;
    text-align: center;
    border-radius: 24px;
    line-height: 26px;
  }
  .msg-search {
    max-width: 300px;
    width: 100%;

    .v-input input {
      max-height: 40px;
    }
  }
  tbody tr {
    height: 52px;
  }
  .graph {
    width: 360px;
    height: 260px;
    position: relative;

    .big {
      bottom: 0;
      position: absolute;
      border-left: 180px solid transparent;
      border-right: 180px solid transparent;
      border-top: 260px solid #577bf9;
      z-index: 3;
    }
    .medium {
      bottom: 0;
      left: 45px;
      position: absolute;
      border-left: 135px solid transparent;
      border-right: 135px solid transparent;
      border-top: 195px solid #94aafb;
      z-index: 3;
    }
    .small {
      bottom: 0;
      left: 90px;
      position: absolute;
      border-left: 90px solid transparent;
      border-right: 90px solid transparent;
      border-top: 130px solid #bac9fd;
      z-index: 3;
    }
    .little {
      bottom: 0;
      left: 135px;
      position: absolute;
      border-left: 45px solid transparent;
      border-right: 45px solid transparent;
      border-top: 65px solid #d3ddfd;
      z-index: 3;
    }
  }
  .graph-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 65px;
    position: relative;
  }
  .graph-item::after {
    content: '';
    position: absolute;
    left: -240px;
    bottom: 0;
    width: 230px;
    height: 1px;
    background: rgb(216, 216, 216);
  }
  .graph-item::before {
    content: '';
    position: absolute;
    left: -10px;
    bottom: -3px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: rgb(216, 216, 216);
  }
  .stat {
    .tab-block {
      height: 42px !important;
      border-bottom: 1px solid rgb(221, 221, 221) !important;
      // border-right: 2px solid rgb(221, 221, 221) !important;
    }
    // .tab-block:last-of-type {
    //   border-bottom: none !important;
    //   background: white !important;
    // }
    .v-tab--disabled {
      opacity: 1;
    }
    .v-tabs-items {
      border-left: 1px solid rgb(221, 221, 221) !important;
    }
    table tr,
    table th,
    table td {
      height: 42px !important;
    }
  }
}
@media screen and (max-width: 649px) {
  .campaign {
    .status-info {
      width: 304px;
      left: calc(50% - 152px);
    }
  }
}
</style>
